import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const initialState = {
  initial: false,
  toolInstalledFormVisibility: false,
  toolInstalledViewFormVisibility: false,
  toolInstalledEditFormVisibility: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  toolInstalled: null,
  toolsInstalled: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
  toolTypes: [
    { _id:1 , name: 'GENERIC TOOL'},
    { _id:2 , name: 'SINGLE TOOL'},
    { _id:3 , name: 'COMPOSIT TOOL'},
  ],
  toolTypesObj: [
    { _id:1 , name: 'GENERIC TOOL'},
    { _id:2 , name: 'SINGLE TOOL'},
    { _id:3 , name: 'COMPOSIT TOOL'},
  ],
  movingPunchConditions: [
    { _id: 1 , name: 'NO PUNCH'},
    { _id: 2 , name: 'PUNCH WHILE JOGGING'},
    { _id: 3 , name: 'PUNCH WHILE RUNNING'}
  ],
  engageOnConditions: [
    { _id: 1 , name: 'PASS'},
    { _id: 2 , name: 'NO CONDITION'},
    { _id: 3 , name: 'PROXIMITY SENSOR'}
  ],
  engageOffConditions: [
    { _id: 1, name: 'PASS'},
    { _id: 2, name: 'TIMER'},
    { _id: 3, name: 'PROXIMITY SENSOR'},
    { _id: 4, name: 'PRESSURE TARGET'},
    { _id: 5, name: 'DISTANCE SENSOR'},
    { _id: 6, name: 'PRESSURE TRIGGERS TIMER'}
  ],
};

const slice = createSlice({
  name: 'toolInstalled',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // SET ADD FORM TOGGLE
    setToolInstalledFormVisibility(state, action){
      state.toolInstalledFormVisibility = action.payload;
    },

    // SET EDIT FORM TOGGLE
    setToolInstalledEditFormVisibility(state, action){
      state.toolInstalledEditFormVisibility = action.payload;
    },

    // SET VIEW TOGGLE
    setToolInstalledViewFormVisibility(state, action){
      state.toolInstalledViewFormVisibility = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET  Tool
    getToolsInstalledSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.toolsInstalled = action.payload;
      state.initial = true;
    },

    // GET Tool
    getToolInstalledSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.toolInstalled = action.payload;
      state.initial = true;
    },

    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },


    // RESET LICENSE
    resetToolInstalled(state){
      state.tool = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // RESET LICENSE
    resetToolsInstalled(state){
      state.tools = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    backStep(state) {
      state.checkout.activeStep -= 1;
    },

    nextStep(state) {
      state.checkout.activeStep += 1;
    },
    // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setToolInstalledFormVisibility,
  setToolInstalledEditFormVisibility,
  setToolInstalledViewFormVisibility,
  resetToolInstalled,
  resetToolsInstalled,
  setResponseMessage,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;

// ----------------------------------------------------------------------

export function addToolInstalled(machineId,params) {
  return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        /* eslint-disable */
          let data = {
              tool: params.tool._id,
              offset: params.offset,
              isApplyWaste: params.isApplyWaste,
              wasteTriggerDistance: params.wasteTriggerDistance,
              isApplyCrimp: params.isApplyCrimp,
              crimpTriggerDistance: params.crimpTriggerDistance,
              isBackToBackPunch: params.isBackToBackPunch,
              isManualSelect: params.isManualSelect,
              isAssign: params.isAssign,
              operations: params.operations,
              // note: params.note,
              toolType: params.toolType.name,
              isActive: params.isActive,
              // singleToolConfig: {},
              // compositeToolConfig:{}
          }
        if( params.toolType.name === 'SINGLE TOOL' ){
          data.singleToolConfig = {}
          if(params.engageSolenoidLocation){
            data.singleToolConfig.engageSolenoidLocation = params.engageSolenoidLocation;
          }
          if(params.returnSolenoidLocation){
            data.singleToolConfig.returnSolenoidLocation = params.returnSolenoidLocation;
          }
          if(params.engageOnCondition){
            data.singleToolConfig.engageOnCondition = params.engageOnCondition.name;
          }
          if(params.engageOffCondition){
            data.singleToolConfig.engageOffCondition = params.engageOffCondition.name;
          }
          if(params.timeOut){
            data.singleToolConfig.timeOut = params.timeOut;
          }
          if(params.engagingDuration){
            data.singleToolConfig.engagingDuration = params.engagingDuration;
          }
          if(params.returningDuration){
            data.singleToolConfig.returningDuration = params.returningDuration;
          }
          if(params.twoWayCheckDelayTime){
            data.singleToolConfig.twoWayCheckDelayTime = params.twoWayCheckDelayTime;
          }
          if(params.homeProximitySensorLocation){
            data.singleToolConfig.homeProximitySensorLocation = params.homeProximitySensorLocation;
          }
          if(params.engagedProximitySensorLocation){
            data.singleToolConfig.engagedProximitySensorLocation = params.engagedProximitySensorLocation;
          }
          if(params.pressureTarget){
            data.singleToolConfig.pressureTarget = params.pressureTarget;
          }
          if(params.distanceSensorLocation){
            data.singleToolConfig.distanceSensorLocation = params.distanceSensorLocation;
          }
          if(params.distanceSensorTarget){
            data.singleToolConfig.distanceSensorTarget = params.distanceSensorTarget;
          }
          if(params.isHasTwoWayCheck){
            data.singleToolConfig.isHasTwoWayCheck = params.isHasTwoWayCheck;
          }
          if(params.isEngagingHasEnable){
            data.singleToolConfig.isEngagingHasEnable = params.isEngagingHasEnable;
          }
          if(params.isReturningHasEnable){
            data.singleToolConfig.isReturningHasEnable = params.isReturningHasEnable;
          }
          if(params.movingPunchCondition){
            data.singleToolConfig.movingPunchCondition = params.movingPunchCondition.name;
          }
        }else if ( params.toolType.name === 'COMPOSIT TOOL' ){
              data.compositeToolConfig = params.compositeToolConfig.filter(config =>  config?.engage?.tool?._id || config?.disengage?.tool?._id ).map(config => ({ engageInstruction: config?.engage?._id ,  disengageInstruction: config?.disengage?._id }))
        }
        /* eslint-enable */ 
      await axios.post(`${CONFIG.SERVER_URL}products/machines/${machineId}/toolsinstalled/`, data);
      dispatch(slice.actions.setResponseMessage('Tool Installed successfully'));
    } catch (error) {
    console.log(error);
    dispatch(slice.actions.hasError(error.Message));
    throw error;
  }
};
}

export function updateToolInstalled(machineId,toolInstallledId,params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
        const data = {
          tool: params.tool._id,
          offset: params.offset,
          isApplyWaste: params.isApplyWaste,
          wasteTriggerDistance: params.wasteTriggerDistance,
          isApplyCrimp: params.isApplyCrimp,
          crimpTriggerDistance: params.crimpTriggerDistance,
          isBackToBackPunch: params.isBackToBackPunch,
          isManualSelect: params.isManualSelect,
          isAssign: params.isAssign,
          operations: params.operations,
          toolType: params.toolType.name,
          isActive: params.isActive,
      }
    if( params.toolType.name === 'SINGLE TOOL' ){
      data.singleToolConfig = {}
      if(params.engageSolenoidLocation){
        data.singleToolConfig.engageSolenoidLocation = params.engageSolenoidLocation;
      }
      if(params.returnSolenoidLocation){
        data.singleToolConfig.returnSolenoidLocation = params.returnSolenoidLocation;
      }
      if(params.engageOnCondition){
        data.singleToolConfig.engageOnCondition = params.engageOnCondition.name;
      }
      if(params.engageOffCondition){
        data.singleToolConfig.engageOffCondition = params.engageOffCondition.name;
      }
      if(params.timeOut){
        data.singleToolConfig.timeOut = params.timeOut;
      }
      if(params.engagingDuration){
        data.singleToolConfig.engagingDuration = params.engagingDuration;
      }
      if(params.returningDuration){
        data.singleToolConfig.returningDuration = params.returningDuration;
      }
      if(params.twoWayCheckDelayTime){
        data.singleToolConfig.twoWayCheckDelayTime = params.twoWayCheckDelayTime;
      }
      if(params.homeProximitySensorLocation){
        data.singleToolConfig.homeProximitySensorLocation = params.homeProximitySensorLocation;
      }
      if(params.engagedProximitySensorLocation){
        data.singleToolConfig.engagedProximitySensorLocation = params.engagedProximitySensorLocation;
      }
      if(params.pressureTarget){
        data.singleToolConfig.pressureTarget = params.pressureTarget;
      }
      if(params.distanceSensorLocation){
        data.singleToolConfig.distanceSensorLocation = params.distanceSensorLocation;
      }
      if(params.distanceSensorTarget){
        data.singleToolConfig.distanceSensorTarget = params.distanceSensorTarget;
      }
      if(params.isHasTwoWayCheck){
        data.singleToolConfig.isHasTwoWayCheck = params.isHasTwoWayCheck;
      }
      if(params.isEngagingHasEnable){
        data.singleToolConfig.isEngagingHasEnable = params.isEngagingHasEnable;
      }
      if(params.isReturningHasEnable){
        data.singleToolConfig.isReturningHasEnable = params.isReturningHasEnable;
      }
      if(params.movingPunchCondition){
        data.singleToolConfig.movingPunchCondition = params.movingPunchCondition.name;
      }
    }else if ( params.toolType.name === 'COMPOSIT TOOL' ){
      data.compositeToolConfig = params.compositeToolConfig.filter(config =>  config?.engage?.tool?._id || config?.disengage?.tool?._id ).map(config => ({ engageInstruction: config?.engage?._id ,  disengageInstruction: config?.disengage?._id }))
    }
      await axios.patch(`${CONFIG.SERVER_URL}products/machines/${machineId}/toolsinstalled/${toolInstallledId}`, data, );
      dispatch(slice.actions.setResponseMessage('Tool Installed updated successfully'));
      dispatch(setToolInstalledEditFormVisibility (false));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error.Message));
      throw error;
    }
  };
}



export function getToolsInstalled( machineId, isMachineArchived ) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        isArchived: false,
        orderBy : {
          createdAt: -1
        }
      }
    if( isMachineArchived ){
      params.archivedByMachine = true;
      params.isArchived = true;
    } 
      const response = await axios.get(`${CONFIG.SERVER_URL}products/machines/${machineId}/toolsinstalled` , { params } );
      dispatch(slice.actions.getToolsInstalledSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Installed Tools loaded successfully'));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error.Message));
      throw error;
    }
  };
}


export function getToolInstalled(machineId,Id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}products/machines/${machineId}/toolsinstalled/${Id}`);
      dispatch(slice.actions.getToolInstalledSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Installed Tool Loaded Successfuly'));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.Message));
      throw error;
    }
  };
}

export function deleteToolInstalled(machineId,toolInstalled, toolType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`${CONFIG.SERVER_URL}products/machines/${machineId}/toolsinstalled/${toolInstalled}` , 
      {
          isArchived: true, 
          toolType,
      });
      dispatch(slice.actions.setResponseMessage(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.Message));
      throw error;
    }
  };
}